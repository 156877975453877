import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  InputAdornment,
} from '@mui/material';
import { FilterIcon, SearchIcon } from 'components/Icons';
import { setFilters } from 'slices/teamsReducer';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { TeamsSearchPanel } from './SearchPanel/TeamsSearchPanel';

const teamTypeOptions = [
  { value: 'all', label: 'All Teams' },
  { value: 'project', label: 'Project Teams' },
  { value: 'organization', label: 'Organization Teams' },
  { value: 'department', label: 'Department Teams' },
];

export const TeamsFilters = ({ selectedRows = [] }) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.teams);
  const [selectedType, setSelectedType] = useState('all');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const { displayPanel, setFullWidth } = useSidebar({
    open: false,
    config: {
      onClose: () => {},
      search: {
        show: true,
        component: TeamsSearchPanel,
        background: true,
      },
    },
  });

  const handleOpenFilters = () => {
    setFullWidth(false);
    displayPanel('search');
  };

  const filterOptions = (value) => {
    dispatch(
      setFilters({
        search: value,
      })
    );
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
    dispatch(
      setFilters({
        teamType: event.target.value,
      })
    );
  };

  return (
    <Box sx={{ p: 1 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Select
              value={selectedType}
              onChange={handleTypeChange}
              displayEmpty
              sx={{
                height: '40px',
                backgroundColor: 'transparent',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
                '& .MuiSelect-select': {
                  color: 'white',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
              }}
            >
              <MenuItem value="" disabled>
                <Typography>Select Team Type</Typography>
              </MenuItem>
              {teamTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search teams..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon size={20} />
                  </InputAdornment>
                ),
              }}
              value={filters?.search || ''}
              onChange={(e) => filterOptions(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '40px',
                  backgroundColor: 'transparent',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '& input': {
                    color: 'white',
                  },
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleOpenFilters}
              sx={{
                height: '40px',
                minWidth: '100px',
                whiteSpace: 'nowrap',
                backgroundColor: filters?.activated ? 'primary.main' : 'transparent',
                '&:hover': {
                  backgroundColor: filters?.activated ? 'primary.dark' : 'rgba(255, 255, 255, 0.08)',
                },
              }}
            >
              <FilterIcon size={15} style={{ marginRight: '8px' }} /> Filters {filters?.activated && '(On)'}
            </Button>
          </Box>
        </Grid>
        {selectedRows.length > 0 && (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setShowDeleteConfirm(true)}
                sx={{
                  height: '40px',
                  minWidth: '100px',
                  whiteSpace: 'nowrap',
                }}
              >
                Remove Teams
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      <Dialog open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)}>
        <DialogTitle>Remove Teams</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove {selectedRows.length} team{selectedRows.length !== 1 ? 's' : ''} from the
            organization?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirm(false)}>Cancel</Button>
          <Button
            onClick={() => {
              // Handle remove teams
              setShowDeleteConfirm(false);
            }}
            color="error"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

TeamsFilters.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string),
};

TeamsFilters.defaultProps = {
  selectedRows: [],
};
