import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { UserPlusIcon } from 'components/Icons/UserPlusIcon';

export const ModalTransferMembers = ({ isOpen, onCancel, onConfirm, currentOrg, orgList }) => {
  const [selectedOrg, setSelectedOrg] = useState('');
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    if (!selectedOrg) return;

    setLoading(true);
    try {
      await onConfirm(selectedOrg);
    } catch (error) {
      console.error('Error transferring members:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => onCancel(setSelectedOrg(''))} maxWidth="xs">
      <DialogTitle>
        <ModalHeaderIcon icon={UserPlusIcon} text="Transfer Members" color="primary" />
      </DialogTitle>
      <DialogContent sx={{ height: '300px' }}>
        <Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Please select the Organization you would like to transfer {currentOrg.name}&apos;s members to. This action
            will transfer ALL members from one org to another. If you want to transfer individual members, please visit
            the members page in the org dashboard to select specific individuals.
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="target-org-label">Target Organization</InputLabel>
            <Select
              labelId="target-org-label"
              value={selectedOrg}
              onChange={(e) => setSelectedOrg(e.target.value)}
              label="Target Organization"
              placeholder="Paste UUID or Select Organization"
            >
              {orgList.map((org) => (
                <MenuItem key={org.id} value={org.id} style={{ fontSize: '16px' }}>
                  {org.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          gap: '1em',
          padding: '2em',
        }}
      >
        <Button onClick={() => onCancel(setSelectedOrg(''))} variant="contained" sx={{ width: '50%' }}>
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={!selectedOrg || loading}
          sx={{
            backgroundColor: '#FF9100',
            color: '#000',
            '&:hover': {
              backgroundColor: '#cc7400',
            },
            width: '50%',
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalTransferMembers.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  currentOrg: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  orgList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
};
