import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTable, useSortBy } from 'react-table';
import { Card } from 'components/Card';
import { Box, Typography } from '@mui/material';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { ContextMenu } from 'components/ContextMenu/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems/ContextMenuList';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { setSelectedTeams } from 'slices/teamsReducer';
import { TeamsFilters } from './TeamsFilters';

// eslint-disable-next-line no-unused-vars
const mockTeams = [
  {
    id: '1',
    name: 'Mega Team 1',
    description: 'Short description of the team. Wrap text and limit characters to fit ...',
    memberCount: 8,
  },
  {
    id: '2',
    name: 'Team Alpha',
    description: 'Short description of the team. Wrap text and limit characters to fit ...',
    memberCount: 8,
  },
  {
    id: '3',
    name: 'Drone Pilots',
    description: 'Short description of the team. Wrap text and limit characters to fit ...',
    memberCount: 15,
  },
  {
    id: '4',
    name: 'Location Scouts',
    description: 'Short description of the team. Wrap text and limit characters to fit ...',
    memberCount: 11,
  },
];

// eslint-disable-next-line no-unused-vars
const TeamActions = ({ row }) => {
  // eslint-disable-next-line no-unused-vars
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ContextMenu>
        <ContextMenuButton onClick={handleMenuOpen} size="small" />
        <ContextMenuList>
          <ContextMenuItem onClick={handleMenuClose}>Edit Team</ContextMenuItem>
          <ContextMenuItem onClick={handleMenuClose}>Remove Team</ContextMenuItem>
        </ContextMenuList>
      </ContextMenu>
    </Box>
  );
};

export const Teams = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const { teams, selectedTeams } = useSelector((state) => state.teams);
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>{row.original.name}</Typography>
            <Typography variant="caption" color="textSecondary">
              {row.original.description}
            </Typography>
          </Box>
        ),
      },
      {
        Header: 'Members',
        accessor: 'memberCount',
        Cell: ({ value }) => (
          <Typography>
            {value} member{value !== 1 ? 's' : ''}
          </Typography>
        ),
      },
      {
        Header: 'Advanced',
        accessor: 'actions',
        Cell: TeamActions,
      },
    ],
    []
  );

  const table = useTable(
    {
      columns,
      data: teams,
    },
    useSortBy
  );

  const handleSelectedIds = (ids) => {
    setSelectedRows(ids);
    dispatch(setSelectedTeams(ids));
  };

  return (
    <Card noPadding>
      <TeamsFilters selectedRows={selectedRows} />
      <GeneralTable table={table} selectRow handleSelectedIds={handleSelectedIds} rowComponent={TeamActions} />
    </Card>
  );
};
