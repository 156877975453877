import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, FormControlLabel, Switch, Checkbox, TextField, InputAdornment } from '@mui/material';
import { SearchIcon } from 'components/Icons';
import { SidebarPanel } from 'components/SidebarPanel';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';
import { setFilters, clearFilters } from 'slices/teamsReducer';

const fileTypeOptions = [
  { value: 'video', label: 'Video' },
  { value: 'image', label: 'Image' },
  { value: 'lidar', label: 'LiDAR' },
];

const externalAccounts = [
  { value: 'comcast', label: 'Comcast' },
  { value: 'netflix', label: 'Netflix Studios' },
  { value: 'water', label: 'Water & Power Corp.' },
];

const tagOptions = [
  { value: 'tag1', label: 'Tag Name' },
  { value: 'tag2', label: 'Tag Name' },
  { value: 'tag3', label: 'Tag Name' },
  { value: 'tag4', label: 'Tag Name' },
];

export const TeamsSearchPanel = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.teams);

  const handleFilterChange = (key, value) => {
    dispatch(setFilters({ [key]: value }));
  };

  const handleClearFilters = () => {
    dispatch(clearFilters());
  };

  const getFilterActions = () => {
    const actions = [];
    if (filters.favorites) {
      actions.push({ text: 'Only Show Favorites', callBack: () => handleFilterChange('favorites', false) });
    }
    if (filters.fileTypes.length > 0) {
      actions.push({ text: 'File Type', callBack: () => handleFilterChange('fileTypes', []) });
    }
    if (filters.externalAccounts.length > 0) {
      actions.push({ text: 'External Account', callBack: () => handleFilterChange('externalAccounts', []) });
    }
    if (filters.tags.length > 0) {
      actions.push({ text: 'Tags', callBack: () => handleFilterChange('tags', []) });
    }
    if (filters.userMembership.length > 0) {
      actions.push({ text: 'Team Membership', callBack: () => handleFilterChange('userMembership', []) });
    }
    return actions;
  };

  return (
    <SidebarPanel
      title="Filters"
      subTitle="Apply filters to table data."
      filters
      handleClearFilters={handleClearFilters}
      handleApplyFilters={() => {}}
    >
      <Box sx={{ p: 1 }}>
        <ClearFilterAction actions={getFilterActions()} />

        <Box sx={{ mb: 3 }}>
          <FormControlLabel
            control={
              <Switch checked={filters.favorites} onChange={(e) => handleFilterChange('favorites', e.target.checked)} />
            }
            label="Only Show Favorites"
          />
        </Box>

        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          File Type
        </Typography>
        <Box sx={{ mb: 3 }}>
          {fileTypeOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checked={filters.fileTypes.includes(option.value)}
                  onChange={(e) =>
                    handleFilterChange(
                      'fileTypes',
                      e.target.checked
                        ? [...filters.fileTypes, option.value]
                        : filters.fileTypes.filter((type) => type !== option.value)
                    )
                  }
                />
              }
              label={option.label}
            />
          ))}
        </Box>

        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          External Account
        </Typography>
        <Box sx={{ mb: 3 }}>
          {externalAccounts.map((account) => (
            <FormControlLabel
              key={account.value}
              control={
                <Checkbox
                  checked={filters.externalAccounts.includes(account.value)}
                  onChange={(e) =>
                    handleFilterChange(
                      'externalAccounts',
                      e.target.checked
                        ? [...filters.externalAccounts, account.value]
                        : filters.externalAccounts.filter((acc) => acc !== account.value)
                    )
                  }
                />
              }
              label={account.label}
            />
          ))}
        </Box>

        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Tag
        </Typography>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search tags..."
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          {tagOptions.map((tag) => (
            <FormControlLabel
              key={tag.value}
              control={
                <Checkbox
                  checked={filters.tags.includes(tag.value)}
                  onChange={(e) =>
                    handleFilterChange(
                      'tags',
                      e.target.checked ? [...filters.tags, tag.value] : filters.tags.filter((t) => t !== tag.value)
                    )
                  }
                />
              }
              label={tag.label}
            />
          ))}
        </Box>

        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Find Team by User Membership
        </Typography>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search users..."
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </SidebarPanel>
  );
};
