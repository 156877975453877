import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, Typography } from '@mui/material';
import { Button } from 'components/Button';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { SidebarPanel } from 'components/SidebarPanel';

export const EditUserPanel = ({ user, onClose }) => {
  const [formData, setFormData] = useState({
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    bio: '',
  });

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || '',
        email: user.email || '',
        phone: user.phone || '',
        bio: '',
      });
    }
  }, [user]);

  const handleChange = (field) => (event) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleSave = () => {
    // TODO: Implement save functionality
    onClose();
  };

  return (
    <SidebarPanel>
      <Box sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ color: 'white', mb: 1 }}>
            {user?.name}
          </Typography>
          <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            UUID: {user?.id}
          </Typography>
        </Box>

        <WidgetDivider />

        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="subtitle1" sx={{ color: 'white', mb: 1 }}>
            User Information
          </Typography>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              label="Name"
              value={formData.firstName}
              onChange={handleChange('firstName')}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                },
              }}
            />
            <TextField
              label="Last Name"
              value={formData.lastName}
              onChange={handleChange('lastName')}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                },
              }}
            />
          </Box>

          <TextField
            label="Email"
            value={formData.email}
            onChange={handleChange('email')}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
              },
              '& .MuiInputBase-input': {
                color: 'white',
              },
            }}
          />

          <TextField
            label="Phone"
            value={formData.phone}
            onChange={handleChange('phone')}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
              },
              '& .MuiInputBase-input': {
                color: 'white',
              },
            }}
          />

          <TextField
            label="Bio"
            value={formData.bio}
            onChange={handleChange('bio')}
            multiline
            rows={4}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
              },
              '& .MuiInputBase-input': {
                color: 'white',
              },
            }}
          />
        </Box>

        {/* Team Memberships Section */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="subtitle1" sx={{ color: 'white', mb: 2 }}>
            Team Memberships
          </Typography>
          <Box sx={{ bgcolor: 'rgba(255, 255, 255, 0.05)', p: 2, borderRadius: 1 }}>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              No teams assigned
            </Typography>
          </Box>
        </Box>

        {/* Project Memberships Section */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="subtitle1" sx={{ color: 'white', mb: 2 }}>
            Project Memberships
          </Typography>
          <Box sx={{ bgcolor: 'rgba(255, 255, 255, 0.05)', p: 2, borderRadius: 1 }}>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              No projects assigned
            </Typography>
          </Box>
        </Box>

        {/* Action Buttons */}
        <Box sx={{ mt: 'auto', pt: 3, display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            onClick={onClose}
            sx={{
              flex: 1,
              bgcolor: 'transparent',
              border: '1px solid rgba(255, 255, 255, 0.23)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.05)',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              flex: 1,
              bgcolor: '#FF9100',
              color: '#000',
              '&:hover': {
                bgcolor: '#FF9100',
                opacity: 0.9,
              },
            }}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </SidebarPanel>
  );
};

EditUserPanel.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
};

EditUserPanel.defaultProps = {
  user: null,
};
